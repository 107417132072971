import React from "react";
import "./countDownPageStyle.css";
import { useRef, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Button, { OutlineButton } from "../../components/button/Button";
import { useEffect } from "react";
import { PropagateLoader } from "react-spinners";
import downtele from "../../assets/teleim.gif";
import Downloaderror from "./Downloaderror";
import downimg from "../../assets/down.gif";
const Parse = require("parse");
const BannerPopUnder = (s, u, z, p) => {
  const bannerpopunder = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannerpopunder.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://arvigorothan.com/tag.min.js`;
      script.async = true;
      // script["data-zone"] = 5636232;
      script.setAttribute("data-zone", 7635896);
      if (bannerpopunder.current) {
        bannerpopunder.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannerpopunder}></div>
      <div id="7635896"></div>
    </>
  );
};
const BannerInt = () => {
  const bannerint = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannerint.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://wugroansaghadry.com/401/8572664`;
      script["data-cfasync"] = false;
      script.async = true;
      if (bannerint.current) {
        bannerint.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannerint}></div>
      <div id=""></div>
    </>
  );
};
const BannerVintage = () => {
  const bannervintage = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannervintage.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://glizauvo.net/401/7635797`;
      script.async = true;
      script.setAttribute("data-zone", 7635797);

      if (bannervintage.current) {
        bannervintage.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannervintage}></div>
      <div id="7635797"></div>
    </>
  );
};
const RenderTime = ({ remainingTime }) => {
  const currentTime = useRef(remainingTime);
  const prevTime = useRef(null);
  const isNewTimeFirstTick = useRef(false);
  const [, setOneLastRerender] = useState(0);

  if (currentTime.current !== remainingTime) {
    isNewTimeFirstTick.current = true;
    prevTime.current = currentTime.current;
    currentTime.current = remainingTime;
  } else {
    isNewTimeFirstTick.current = false;
  }

  // force one last re-render when the time is over to tirgger the last animation
  if (remainingTime === 0) {
    setTimeout(() => {
      setOneLastRerender((val) => val + 1);
    }, 20);
  }

  const isTimeUp = isNewTimeFirstTick.current;

  return (
    <div className="time-wrapper">
      <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
        {remainingTime}
      </div>
      {prevTime.current !== null && (
        <div
          key={prevTime.current}
          className={`time ${!isTimeUp ? "down" : ""}`}
        >
          {prevTime.current}
        </div>
      )}
    </div>
  );
};
const CountDownPage = ({ embedLinks, hubCloudLink}) => {
  const Duration = 5;
  const [isTimerFinished, setIsTimerFinished] = useState(false);
  const [downloaderror, setDownloaderror] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const platformBaseUrls = {
    streamwish: "https://embedwish.com/f/",
    vihide: "https://vidhidepro.com/d/",
    upnshare: "https://sf.upns.xyz/#",
    streamruby: "https://showflix.beauty/d/",
  };
  const platformDescriptions = {
    upnshare: "(FHD) Quality",
    streamruby: "Multi Quality",
    vihide: "720p/360p Quality",
    streamwish: "1080p Quality",
  };
  // Handler for selecting a platform
  const handlePlatformSelect = (platform) => {
    //setSelectedPlatform(platform);
    let downloadLink = `${platformBaseUrls[platform]}${embedLinks[platform]}`
    if(platform==="upnshare"){
      downloadLink +="&dl=1"
    }
    window.open(downloadLink, "_blank")?.focus();
  };
  const hubcloudHandler = () => {
    const huburl=`https://showflix.shop/archives/${hubCloudLink.split("archives/")[1]}`;
    window.open(`${huburl}`, "_blank")?.focus();
  };
  return (
    <>
      <BannerInt></BannerInt> {/*monetag ads interstitial*/}
      <BannerVintage></BannerVintage>
      {downloaderror ? (
        <Downloaderror></Downloaderror>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "900px",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <BannerPopUnder></BannerPopUnder> {/*monetag ads*/}
            <br></br>
            {!isTimerFinished ? (
              <>
                <div className="timer-wrapper">
                  <CountdownCircleTimer
                    onComplete={() => {
                      setIsTimerFinished(true);
                    }}
                    isPlaying
                    duration={Duration}
                    colors={["#7FFFD4", "#40E0D0", "#00FFFF", "#E0FFFF"]}
                    colorsTime={[5, 3, 2, 0]}
                  >
                    {RenderTime}
                  </CountdownCircleTimer>
                </div>
                <br></br>
                <h1>Please Wait...</h1>
              </>
            ) : (
              <>
                <div
                  style={{
                    textAlign: "center",
                    marginLeft: "25px",
                    marginRight: "25px",
                    marginBottom: "25px",
                    color: "black",
                  }}
                >
                   {Object.keys(embedLinks).map((platform) => (
                      <Button
                        key={platform}
                        onClick={() => handlePlatformSelect(platform)}
                      >
                        <img
                          style={{
                            maxWidth: 75,
                            maxHeight: 25,
                            borderRadius: 5,
                          }}
                          src={downimg}
                          alt="Download"
                        />
                        <span style={{ color: "black"}}>
                          {" "}
                        {platformDescriptions[platform]}
                        </span>
                      </Button>
                    ))}
                    {hubCloudLink && (
                      <OutlineButton className="butn" onClick={hubcloudHandler}>
                        {" "}
                        {
                          <>
                            {" "}
                            <img
                              style={{
                                maxWidth: 75,
                                maxHeight: 25,
                                borderRadius: 5,
                              }}
                              src={downtele}
                              alt="HD Download"
                            />{" "}
                            <span>Download HubCloud & Telegram</span>{" "}
                          </>
                        }
                      </OutlineButton>
                    )}
                </div>
              </>
            )}
            <br></br>
          </div>
        </>
      )}
    </>
  );
};

export default CountDownPage;
