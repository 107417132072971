import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { OutlineButton } from "../../components/button/Button";
import { PropagateLoader } from "react-spinners";
import directdown from "../../assets/direct-download.gif";
const FileDisplay = ({ data ,token}) => {
  const formatSize = (size) => {
    if (size >= 1e9) {
      return `${(size / 1e9).toFixed(2)} GB`;
    }
    return `${(size / 1e6).toFixed(2)} MB`;
  };
  const workers = [
    "https://godown.donkingsmaker.workers.dev/",
    "https://godown.videoshowflix.workers.dev/",
    "https://godown.contact-freefinder.workers.dev/",
    "https://godown.indiangovtjobseekers.workers.dev/"
  ];
  
  let currentWorkerIndex = 0;
  
  const getNextWorker = () => {
    // Round-robin strategy
    const worker = workers[currentWorkerIndex];
    currentWorkerIndex = (currentWorkerIndex + 1) % workers.length;
    return worker;
  };
  const handleDownload = (downloadUrl, token) => {
    const workerUrl = getNextWorker();
    const url = `${workerUrl}?downloadUrl=${encodeURIComponent(downloadUrl)}&token=${encodeURIComponent(token)}`;
    window.open(url, "_blank");
  };
  
  return (
    <>
      <div
        className="banner"
        style={{
          backgroundImage: `url(${
            data.children[Object.keys(data.children)[0]].thumbnail
          })`,
          height: "65vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        style={{
          padding: "20px",
          fontFamily: "Arial, sans-serif",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "-320px",
          position: "relative",
          padding: "0 2rem",
        }}
      >
        <h1 style={{ color: "green", textAlign: "center" }}>
          Direct Download Link Generated !!!
        </h1>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {Object.values(data.children).map((file) => (
            <div
              key={file.id}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
                boxShadow: "0px 4px 6px rgba(237, 237, 237, 0.45)",
                gap: "20px",
              }}
            >
              <img
                src={file.thumbnail}
                alt={file.name}
                style={{
                  width: "100px",
                  height: "auto",
                  borderRadius: "5px",
                  flexShrink: 0,
                }}
              />
              <div style={{ flexGrow: 1, minWidth: "200px" }}>
                <h3
                  style={{
                    margin: "0 0 10px",
                    wordBreak: "break-word",
                    fontSize: "1rem",
                  }}
                >
                  <span style={{ fontWeight: "bold", color: "violet" }}>
                    File Name:{" "}
                  </span>
                  <span style={{ fontWeight: "bold", color: "lightgray" }}>
                    {file.name}
                  </span>
                </h3>
                <p
                  style={{
                    margin: "0 0 5px",
                    wordBreak: "break-word",
                  }}
                >
                  <span style={{ fontWeight: "bold", color: "yellow" }}>
                    Size:{" "}
                  </span>
                  <span style={{ fontWeight: "bold", color: "lightgray" }}>
                    {formatSize(file.size)}
                  </span>
                </p>
              </div>
              <OutlineButton
                className="butn"
                onClick={() => handleDownload(file.link,token)}
                style={{
                  flexShrink: 0,
                  padding: "5px 10px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "0.9rem",
                }}
              >
                <img
                  style={{
                    maxWidth: "75px",
                    maxHeight: "25px",
                    borderRadius: "5px",
                  }}
                  src={directdown}
                  alt="HD Download"
                />
                <span>Download</span>
              </OutlineButton>
            </div>
          ))}
        </div>
      </div>

      <style>
        {`
      @media (max-width: 768px) {
        .banner {
          height: 50vh !important;
        }
        h1 {
          font-size: 1.5rem;
        }
        div[style*="padding: 20px;"] {
          margin-top: -250px !important;
        }
      }
    `}
      </style>
    </>
  );
};

const GofileLinkExtractor = () => {
  const propspar = useParams();
  const downloadPageLastPart = propspar.id;
  const [contentsData, setContentsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token,setToken]=useState();
  // Function to fetch the token
  const fetchToken = async () => {
    const tokenApiUrl = "https://api.gofile.io/accounts";
    try {
      const response = await fetch(tokenApiUrl, {
        method: "POST",
        referrerPolicy: "no-referrer",
      });
      const data = await response.json();
      if (data.status === "ok" && data.data.token) {
        setToken(data.data.token);
         return data.data.token;
      } else {
        throw new Error("Failed to retrieve token.");
      }
    } catch (err) {
      throw new Error(err.message || "An error occurred while fetching token.");
    }
  };

  // Function to fetch contents data
  const fetchContentsData = async (token) => {
    const contentsApiUrl = `https://api.gofile.io/contents/${downloadPageLastPart}?token=${token}&wt=4fd6sg89d7s6&contentFilter=&page=1&pageSize=1000&sortField=name&sortDirection=1`;

    try {
      const response = await fetch(contentsApiUrl, {
        referrerPolicy: "no-referrer", // Prevent Referer header from being sent
      });
      const data = await response.json();

      if (data.status === "ok" && data.data.children) {
        return data.data;
      } else {
        throw new Error("Failed to retrieve contents data.");
      }
    } catch (err) {
      throw new Error(
        err.message || "An error occurred while fetching contents data."
      );
    }
  };

  // UseEffect to call the APIs on page load
  useEffect(() => {
    const fetchContentsOnLoad = async () => {
      setLoading(true);
      setError(null);
      setContentsData(null);

      try {
        const token = await fetchToken();
        const data = await fetchContentsData(token);
        setContentsData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (downloadPageLastPart) {
      fetchContentsOnLoad();
    } else {
      setError("No download page last part provided.");
    }
  }, []);

  return (
    <div style={{ paddingBottom: "5rem", fontFamily: "Arial, sans-serif" }}>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "700px",
            width: "100%",
          }}
        >
          <div>
            <PropagateLoader color="#09dfeb" loading="true" size={15} />
          </div>
        </div>
      )}
      {error && (
        <div style={{ color: "red", marginTop: "20px" }}>
          <strong>Error:</strong> {error}
        </div>
      )}
      {contentsData && <FileDisplay data={contentsData} token={token} />}
    </div>
  );
};

export default GofileLinkExtractor;
