import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router";
import StarIcon from "@mui/icons-material/Star";
import "./detail.scss";
import axios from "axios";
import Seasons from "./Seasons";
import PropagateLoader from "react-spinners/PropagateLoader";
import MovieList from "../../components/movie-list/MovieList";
import Button, { OutlineButton } from "../../components/button/Button";
import downimage from "../../assets/gdrive1.gif";
import telelogo from "../../assets/telegramlogo.gif";
import playimg from "../../assets/play.gif";
import downimg from "../../assets/down.gif";
import downtele from "../../assets/teleim.gif";
import directdown from "../../assets/direct-download.gif";
import SeasonsList from "./SeasonsList";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import CountDownPage from "./CountDownPage";
import PreloadImage from "../../PreloadImage";
import placeholder1 from "../../assets/sfgif.gif";
const Parse = require("parse");
const Detail = () => {
  const history = useHistory();
  const { category, id } = useParams();
  const [item, setItem] = useState(null);
  const [embedlinks, setEmbedLinks] = useState(null);
  const [seasons, setSeasons] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hdloading, sethdloading] = useState(false);
  const [dloading, setdloading] = useState(false);
  const [countDp, setCountDp] = useState(false);
  useEffect(() => {
    setLoading(true);
    const getDetail = async () => {
      if (category === "movie") {
        try {
          const query = new Parse.Query("moviesv2");
          const results = await query.get(id);
          setItem(results);
          setLoading(false);
        } catch {
          console.log("error");
          setLoading(true);
        }
      } else {
        try {
          const seriesQuery = new Parse.Query("seriesv2");
          const seriesResult = await seriesQuery.get(id);
          setItem(seriesResult);
          // Fetch Seasons data
          const seasonsQuery = new Parse.Query("seasonv2");
          seasonsQuery.equalTo("seriesId", seriesResult.id); // Filter by seriesId
          const seasonsResults = await seasonsQuery.find();
          setSeasons(seasonsResults.map((s) => s.toJSON())); // Map Parse Objects to JSON

          // Fetch Episodes data
          let allEpisodes = [];
          for (const season of seasonsResults) {
            const episodesQuery = new Parse.Query("episodev2");
            episodesQuery.equalTo("seasonId", season.id);
            const episodesResults = await episodesQuery.find();
            const episodes = episodesResults.map((e) => e.toJSON());
            allEpisodes = [...allEpisodes, ...episodes];
          }
          setEpisodes(allEpisodes);
          setLoading(false);
        } catch (e) {
          console.log("error", e);
        }
      }
    };
    getDetail();
  }, [category, id]);
  const playHandler = (category, id) => {
    try {
      history.push(`/play/${category}-${id}`);
    } catch (error) {
      console.log(error);
    }
  };
  const dHandler = (item) => {
    let embedLink;
    if (typeof item.toJSON === "function") {
      embedLink = item.toJSON().embedLinks;
    } else if (item.embedLinks) {
      embedLink = item.embedLinks;
    }
    setdloading(true);
    setEmbedLinks(embedLink);
    setdloading(false);
    setCountDp(true);
  };
  const hdHandler = (item) => {
    sethdloading(true);
    var hddownloadlink = item.attributes.hdLink;
    const words = hddownloadlink.split("/");
    var hdlink = `https://showflix.shop/archives/${words[4]}`;
    const apicall = `https://shrinkme.io/api?api=5f859d1a8a03b012370cbe649bd1a6dfcac85fe6&url=${hdlink}`;
    axios(apicall)
      .then((response) => {
        var shorturl = response.data.shortenedUrl;
        sethdloading(false);
        window.open(shorturl || item.attributes.hdLink, "_blank")?.focus();
      })
      .catch((error) => console.log(error));
  };
  const hubcloudHandler = (item) => {
    sethdloading(true);
    var hddownloadlink = item.attributes.hubCloudLink;
    const words = hddownloadlink.split("/");
    var hdlink = `https://showflix.shop/archives/${words[4]}`;
    const apicall = `https://shrinkme.io/api?api=5f859d1a8a03b012370cbe649bd1a6dfcac85fe6&url=${hdlink}`;
    axios(apicall)
      .then((response) => {
        var shorturl = response.data.shortenedUrl;
        sethdloading(false);
        window.open(shorturl || item.attributes.hdLink, "_blank")?.focus();
      })
      .catch((error) => console.log(error));
  };
  const directDownlodHandler =(item) => {
    const currentDomain = window.location.hostname;
    let linkValue;
    sethdloading(true);
    if (typeof item.toJSON === "function") {
      linkValue = item.toJSON().goFile;
    } else if (item.goFile) {
      linkValue = item.goFile;
    }
    if (linkValue) {
      let link=`https://${currentDomain}/godownload/${linkValue}`;
      console.log(link)
      const apicall = `https://shrinkme.io/api?api=5f859d1a8a03b012370cbe649bd1a6dfcac85fe6&url=${link}`;
      axios(apicall)
        .then((response) => {
          var shorturl = response.data.shortenedUrl;
          sethdloading(false);
          if(shorturl){
          window.open(shorturl , "_blank")?.focus();
          }else{
            history.push(`/godownload/${linkValue}`);
          }
        })
        .catch((error) => console.log(error));
    }
  };
  const telegramlink = () => {
    window.open("https://telegram.me/showflix_movie", "_blank")?.focus();
  };

  return (
    <>
      {!countDp ? (
        <>
          {!loading ? (
            <>
              <div
                className="banner"
                style={{
                  backgroundImage: `url(${item.attributes.backdropURL.replace(
                    "www.themoviedb.org",
                    "image.tmdb.org"
                  )})`,
                }}
              ></div>
              <div className="mb-3 movie-content container">
                <div className="movie-content__poster">
                  <div>
                    <PreloadImage
                      lazy
                      src={item.attributes.posterURL.replace(
                        "www.themoviedb.org",
                        "image.tmdb.org"
                      )}
                      placeholder={placeholder1}
                    ></PreloadImage>
                  </div>
                </div>
                <div className="movie-content__info">
                  <h1 className="title">
                    {item.attributes.name} ({item.attributes.releaseYear})
                  </h1>
                  <div className="genres">
                    <h5
                      style={{
                        borderWidth: "5px",
                        borderStyle: "double",
                        borderColor: "#00FFD1",
                        borderRadius: "10px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        marginRight: "8px",
                      }}
                    >
                      Language :
                    </h5>
                    {item.attributes.languages.map((language, index) => (
                      <span className="genres__item">
                        <span key={index}>{language}</span>
                      </span>
                    ))}
                  </div>
                  {item.attributes.genres.length !== 0 && (
                    <div className="genres">
                      <h5
                        style={{
                          borderWidth: "5px",
                          borderStyle: "double",
                          borderColor: "#00FFD1",
                          borderRadius: "10px",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          marginRight: "8px",
                        }}
                      >
                        Genres :
                      </h5>
                      {item.attributes.genres.map((genres, index) => (
                        <span className="genres__gitem">
                          <span key={index}>{genres}</span>
                        </span>
                      ))}
                    </div>
                  )}
                  <div>
                    <div className="genres">
                      <h5
                        style={{
                          borderWidth: "5px",
                          borderStyle: "double",
                          borderColor: "#00FFD1",
                          borderRadius: "10px",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          marginRight: "8px",
                        }}
                      >
                        Rating :
                      </h5>
                      <div className="genres__rating">
                        <div
                          style={{ paddingRight: "1px", paddingLeft: "1px" }}
                        >
                          {item.attributes.rating}
                        </div>
                        <div>
                          <StarIcon
                            style={{ color: "yellow", blockSize: 12 }}
                          ></StarIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="genres">
                      <h5
                        style={{
                          borderWidth: "5px",
                          borderStyle: "double",
                          borderColor: "#00FFD1",
                          borderRadius: "10px",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                        }}
                      >
                        Overview :
                      </h5>
                    </div>
                    <br></br>
                    <p className="overview">
                      &emsp; &emsp;{item.attributes.storyline}
                    </p>
                  </div>
                  {item.attributes.Note && (
                    <h3 style={{ color: "red" }}>
                      {" "}
                      Note ⚠️ : {item.attributes.Note}{" "}
                    </h3>
                  )}
                  <br></br>
                  {item.attributes.hdLink && (
                    <div>
                      <span
                        style={{
                          color: "#FFFFDE",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        (For Fast Download 🚀 & High Quality use G-Drive link)
                      </span>
                    </div>
                  )}
                  <div className="btns">
                    {category === "movie" && (
                      <>
                        <Button onClick={() => playHandler(category, item.id)}>
                          <img
                            style={{
                              maxWidth: 75,
                              maxHeight: 25,
                              borderRadius: 5,
                            }}
                            src={playimg}
                            alt="Play"
                          />{" "}
                          <span>Play</span>
                        </Button>
                        <OutlineButton onClick={() => dHandler(item)}>
                          {dloading ? (
                            <span>Getting Link...</span>
                          ) : (
                            <>
                              <img
                                style={{
                                  maxWidth: 75,
                                  maxHeight: 25,
                                  borderRadius: 5,
                                }}
                                src={downimg}
                                alt="Download"
                              />{" "}
                              <span>Download</span>
                            </>
                          )}
                        </OutlineButton>
                      </>
                    )}
                    {item.attributes.hdLink && (
                      <OutlineButton
                        className="butn"
                        onClick={() => hdHandler(item)}
                      >
                        {" "}
                        {hdloading ? (
                          <span>Getting Link...</span>
                        ) : (
                          <>
                            {" "}
                            <img
                              style={{
                                maxWidth: 75,
                                maxHeight: 25,
                                borderRadius: 5,
                              }}
                              src={downimage}
                              alt="HD Download"
                            />{" "}
                            <span>G - Drive</span>{" "}
                          </>
                        )}
                      </OutlineButton>
                    )}
                    {item.attributes.hubCloudLink && (
                      <OutlineButton
                        className="butn"
                        onClick={() => hubcloudHandler(item)}
                      >
                        {" "}
                        {hdloading ? (
                          <span>Getting Link...</span>
                        ) : (
                          <>
                            {" "}
                            <img
                              style={{
                                maxWidth: 75,
                                maxHeight: 25,
                                borderRadius: 5,
                              }}
                              src={downtele}
                              alt="HD Download"
                            />{" "}
                            <span>HubCloud & Telegram</span>{" "}
                          </>
                        )}
                      </OutlineButton>
                    )}
                    {item.attributes.goFile && (
                      <OutlineButton
                        className="butn"
                        onClick={() => directDownlodHandler(item)}
                      >
                        {" "}
                        {hdloading ? (
                          <span>Getting Link...</span>
                        ) : (
                          <>
                            {" "}
                            <img
                              style={{
                                maxWidth: 75,
                                maxHeight: 25,
                                borderRadius: 5,
                              }}
                              src={directdown}
                              alt="HD Download"
                            />{" "}
                            <span style={{ wordWrap: "break-word" }}>
                              Direct Download
                            </span>{" "}
                          </>
                        )}
                      </OutlineButton>
                    )}
                  </div>
                  <br></br>
                  <span style={{ fontWeight: "bold" }}>Share : </span>
                  <span style={{ paddingLeft: 5 }}> </span>
                  <FacebookShareButton
                    quote={`${item.attributes.movieName} - ${item.attributes.category} 𝕄𝕠𝕧𝕚𝕖 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                    url={`${window.location.href}`}
                  >
                    <FacebookIcon size={36} borderRadius={10} />
                  </FacebookShareButton>
                  <span style={{ paddingLeft: 5 }}> </span>
                  <TelegramShareButton
                    title={`${item.attributes.movieName} - ${item.attributes.category} 𝕄𝕠𝕧𝕚𝕖 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                    url={`${window.location.href}`}
                  >
                    <TelegramIcon size={36} borderRadius={10} />
                  </TelegramShareButton>
                  <span style={{ paddingLeft: 5 }}> </span>
                  <TwitterShareButton
                    title={`${item.attributes.movieName} - ${item.attributes.category} 𝕄𝕠𝕧𝕚𝕖 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                    url={`${window.location.href}`}
                  >
                    <TwitterIcon size={36} borderRadius={10}></TwitterIcon>
                  </TwitterShareButton>
                  <span style={{ paddingLeft: 5 }}> </span>
                  <WhatsappShareButton
                    title={`${item.attributes.movieName} - ${item.attributes.category} 𝕄𝕠𝕧𝕚𝕖 - 𝐒𝐇𝐎𝐖𝐅𝐋𝐈𝐗`}
                    separator=":: "
                    url={`${window.location.href}`}
                  >
                    <WhatsappIcon size={36} borderRadius={10}></WhatsappIcon>
                  </WhatsappShareButton>
                  <br></br>
                  <br></br>
                  <span style={{ paddingLeft: 10 }}>
                    <OutlineButton className="butn" onClick={telegramlink}>
                      {" "}
                      <>
                        {" "}
                        <img
                          style={{
                            maxWidth: 25,
                            maxHeight: 25,
                            borderRadius: 5,
                          }}
                          src={telelogo}
                          alt="Telegram link"
                        />{" "}
                        <span>Join us</span>{" "}
                      </>
                    </OutlineButton>
                  </span>
                </div>
              </div>
              <div>
                {category === "series" && (
                  <SeasonsList
                    seasons={seasons}
                    episodes={episodes}
                    playHandler={playHandler}
                    dHandler={dHandler}
                    directDownlodHandler={directDownlodHandler}
                  />
                )}
              </div>
              <div className="container">
                <br></br>
                <div className="section mb-3">
                  <div className="section__header mb-2">
                    <h2>Similar</h2>
                  </div>
                  <MovieList
                    category={category}
                    type={
                      item.attributes.category || item.attributes.seriesCategory
                    }
                    id={item.id}
                  />
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "700px",
                width: "100%",
              }}
            >
              <div>
                <PropagateLoader color="#09dfeb" loading="true" size={15} />
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <CountDownPage
            embedLinks={embedlinks}
            hubCloudLink={item.attributes.hubCloudLink}
          ></CountDownPage>
        </>
      )}
    </>
  );
};

export default Detail;
