import React from 'react';

import { Route, Switch } from 'react-router-dom';
import Play from '../pages/Play';
import Home from '../pages/Home';
import SignIn from '../pages/SignIn';
import Catalog from '../pages/Catalog';
import Detail from '../pages/detail/Detail';
import Series from '../pages/Series';
import Termscondiation from '../pages/Termscondiation';
import SignUp from '../pages/SignUp';
import GofileLinkExtractor from '../pages/detail/GoFileLinkExtractor';
const Routes = () => {
    return (
        <Switch>
            <Route
                path='/godownload/:id'
                component={GofileLinkExtractor}
            />
             <Route
                path='/play/:id'
                component={Play}
            />
               
             <Route
                path='/terms'
                component={Termscondiation}
            />
             <Route
                path='/seriesHome'
                component={Series}
            />
             <Route
                path='/lan/:category/:type'
                component={Catalog}
            />
            <Route
                path='/:category/search/:keyword'
                component={Catalog}
            />
            <Route
                path='/:category/:id'
                component={Detail}
            />
    
             <Route
                path='/:category'
                component={Catalog}
            />
        
            <Route
                path='/'
                exact
                component={Home}
            />
        </Switch>
    );
}

export default Routes;
