import React from "react";
import EpisodesList from "./EpisodesList";
import directdown from "../../assets/direct-download.gif";
import DownloadIcon from "@mui/icons-material/Download";
import Button, { OutlineButton } from "../../components/button/Button";
const SeasonsList = ({ seasons, episodes, playHandler, dHandler,directDownlodHandler }) => {
  return (
    <>
      {seasons.length > 0 ? (
        <div>
          {seasons.map((season) => (
            <div key={season.id} className="seasoncard">
              <h1
                style={{
                  color: "#ff8000",
                  padding: "15px",
                  fontWeight: "bolder",
                }}
              >
                Season {season.seasonNumber}
              </h1>
              <EpisodesList
                episodes={episodes.filter(
                  (ep) => ep.seasonId === season.objectId
                )}
                playHandler={playHandler}
                dHandler={dHandler}
              />
              {season.goFile&&
              (<><h3
                style={{ color: "black", padding: "15px", textAlign: "center" }}
              >
                Direct Download
                <DownloadIcon
                  style={{
                    fontSize: "medium",
                    marginLeft: "2px",
                    marginTop: "2px",
                  }}
                ></DownloadIcon>
              </h3>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <OutlineButton
                  onClick={() => { directDownlodHandler(season)}}
                  className="botn"
                >
                  Season {season.seasonNumber} Directlink {"  "}
                  <img
                    style={{
                      maxWidth: 75,
                      maxHeight: 25,
                      borderRadius: 5,
                    }}
                    src={directdown}
                    alt="HD Download"
                  />
                </OutlineButton>
              </div></>)}
            </div>
          ))}
        </div>
      ) : (
        <h1
          style={{
            padding: "15px",
            textAlign: "center",
            fontStyle: "italic",
            color: "#999",
          }}
        >
          Will upload Seasons soon ...
        </h1>
      )}
    </>
  );
};

export default SeasonsList;
