import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import PropagateLoader from "react-spinners/PropagateLoader";
import "./movie-grid.scss";
import Select from "react-select";
import MovieCard from "../movie-card/MovieCard";
import Button, { OutlineButton } from "../button/Button";
import Input from "../input/Input";
import Adcomp from "../../ads/Adcomp";
import NotFound from "../../assets/notfound.gif";
import { useDebounce } from "use-debounce";
import { yellow } from "@mui/material/colors";

const Parse = require("parse");
const MovieGrid = (props) => {
  const [category, setCategory] = useState(props.category);
  const options = [
    { value: "All", label: "All Language" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
    { value: "Tamil Dubbed", label: "Tamil Dubbed" },
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Malayalam", label: "Malayalam" },
  ];
  const optionsc = [
    { value: "all", label: "All" },
    { value: "movie", label: "Movies" },
    { value: "series", label: "Series" },
  ];
  const [items, setItems] = useState([]);
  const [seriesItems, setSeriesItems] = useState([]);
  const [type, setType] = useState([props.type]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingmore, setLoadingMore] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [totalmvpage, settotalmvpage] = useState(0);
  //   var displayLimit = 20;
  //   if (props.category != "all") {
  //     displayLimit = 50;
  //   }
  const { keyword } = useParams();
  const [searchTerm, setSearchTerm] = useState(keyword);
  const [searchvalue] = useDebounce(searchTerm, 800);
  const getList = async () => {
    setLoading(true);
    let response1 = null;
    let response2 = null;
    var displayLimit = 20;
    if (category != "all") {
      displayLimit = 50;
    }
    if (searchvalue === undefined) {
      const Movies = Parse.Object.extend("moviesv2");
      const query = new Parse.Query(Movies);
      if (type[0] !== "All") {
        query.containedIn("languages", type);
      }
      query.limit(displayLimit);
      query.descending("updatedAt");
      query.count().then(function (result) {
        settotalmvpage(Math.ceil(result / displayLimit));
      });
      response1 = await query.find();
      const shuffledArray = response1; //response.sort((a, b) => 0.5 - Math.random());
      setItems(shuffledArray);

      const Series = Parse.Object.extend("seriesv2");
      const querys = new Parse.Query(Series);
      if (type[0] !== "All") {
        query.containedIn("languages", type);
      }
      querys.limit(displayLimit);
      querys.descending("updatedAt");
      response2 = await querys.find();
      const shuffledArrays = response2; // response.sort((a, b) => 0.5 - Math.random());
      setSeriesItems(response2);
      setLoading(false);
    } else {
      const Movies = Parse.Object.extend("moviesv2");
      const query = new Parse.Query(Movies);
      query.matches("name", searchvalue, "i");
      if (type[0] !== "All") {
        query.containedIn("languages", type);
      }
      query.descending("updatedAt");
      response1 = await query.find();
      setItems(response1);
      if (response1.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
      const Series = Parse.Object.extend("seriesv2");
      const querys = new Parse.Query(Series);
      querys.matches("name", searchvalue, "i");
      if (type[0] !== "All") {
        query.containedIn("languages", type);
      }
      querys.descending("updatedAt");
      response2 = await querys.find();
      if (response2.length === 0) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
      setSeriesItems(response2);
      setLoading(false);
    }
  };
  useEffect(() => {
    getList();
  }, [category, keyword, type, searchvalue]);

  const loadMore = async () => {
    let response1 = null;
    let response2 = null;
    var displayLimit = 20;
    if (category != "all") {
      displayLimit = 50;
    }
    if (keyword === undefined) {
      setLoadingMore(true);
      const Movies = Parse.Object.extend("moviesv2");
      const query = new Parse.Query(Movies);
      query.descending("updatedAt");
      if (type[0] !== "All") {
        query.containedIn("languages", type);
      }
      query.limit(displayLimit);
      query.skip(page * displayLimit);
      response1 = await query.find();
      setItems([...items, ...response1]);
      const Series = Parse.Object.extend("seriesv2");
      const querys = new Parse.Query(Series);
      querys.descending("updatedAt");
      if (type[0] !== "All") {
        query.containedIn("languages", type);
      }
      querys.limit(displayLimit);
      querys.skip(page * displayLimit);
      response2 = await querys.find();
      setSeriesItems([...seriesItems, ...response2]);
    } else {
      const Movies = Parse.Object.extend("moviesv2");
      const query = new Parse.Query(Movies);
      query.matches("name", keyword, "i");
      response1 = await query.find();
      setItems([...items, ...response1]);
      const Series = Parse.Object.extend("seriesv2");
      const querys = new Parse.Query(Series);
      querys.matches("name", keyword, "i");
      response2 = await querys.find();
      setSeriesItems([...seriesItems, ...response2]);
    }

    setPage(page + 1);
    setLoadingMore(false);
  };
  const setSelectedOption = (value) => {
    setType([value.value]);
    setPage(1);
  };
  const setSelectedOptionc = (value) => {
    setCategory(value.value);
    setPage(1);
  };
  return (
    <>
      <div className="section mb-3">
        <MovieSearch
          category={category}
          keyword={keyword}
          setSearchTerm={setSearchTerm}
          getList={getList}
          searchTerm={searchTerm}
        />
      </div>

      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        <h3 style={{ marginRight: "5px", fontFamily: "cursive" }}>
          Choose Category :
        </h3>
        <Select
          isSearchable={false}
          theme={(theme) => ({
            ...theme,
            borderRadius: 10,
            colors: {
              ...theme.colors,
              primary25: "black",
              primary: "black",
              neutral0: "#4bb9de",
              neutral5: "white",
            },
          })}
          defaultValue={
            optionsc[
              optionsc.findIndex((optionsc) => optionsc.value === category)
            ]
          }
          options={optionsc}
          onChange={(value) => {
            setSelectedOptionc(value);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        <h3 style={{ marginRight: "5px", fontFamily: "cursive" }}>
          Choose Language :
        </h3>
        <Select
          isSearchable={false}
          theme={(theme) => ({
            ...theme,
            borderRadius: 10,
            colors: {
              ...theme.colors,
              primary25: "black",
              primary: "black",
              neutral0: "#4bb9de",
              neutral5: "white",
            },
          })}
          defaultValue={
            options[options.findIndex((options) => options.value === type)]
          }
          options={options}
          onChange={(value) => {
            setSelectedOption(value);
          }}
        />
      </div>
      <br></br>
      <>
        {!loading ? (
          <>
            {category != "series" && (
              <>
                {!items.length ? (
                  <h1 style={{ textAlign: "center", color: "coral" }}>
                    {" "}
                    No Match Found in {type} Movies
                  </h1>
                ) : (
                  <>
                    {" "}
                    {searchvalue ? (
                      <h1 style={{ textAlign: "center", color: "lightgreen" }}>
                        Search Result For " {searchvalue} " in {type} Movies
                      </h1>
                    ) : (
                      <h1 style={{ textAlign: "center", color: "#fcba03" }}>
                        {type} Movies
                      </h1>
                    )}
                  </>
                )}
                <div className="movie-grid">
                  {items.map((item, i) => (
                    <MovieCard category="movie" item={item} key={i} />
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "20px",
                  }}
                >
                  {loadingmore && (
                    <PropagateLoader color="#09dfeb" loading="true" size={15} />
                  )}
                </div>
                <div className="movie-grid__loadmore">
                  {!empty && items.length > 14 && (
                    <OutlineButton className="small" onClick={loadMore}>
                      Load more ⬇️
                    </OutlineButton>
                  )}
                </div>
              </>
            )}
            <br></br>
            <br></br>
            {category != "movie" && (
              <>
                {!seriesItems.length ? (
                  <h1 style={{ textAlign: "center", color: "coral" }}>
                    {" "}
                    No Match Found in {type} Series
                  </h1>
                ) : (
                  <>
                    {" "}
                    {searchvalue ? (
                      <h1 style={{ textAlign: "center", color: "lightgreen" }}>
                        Search Result For " {searchvalue} " in {type} Series
                      </h1>
                    ) : (
                      <h1 style={{ textAlign: "center", color: "#fcba03" }}>
                        {type} Series
                      </h1>
                    )}
                  </>
                )}
                <div className="movie-grid">
                  {seriesItems.map((item, i) => (
                    <MovieCard category="series" item={item} key={i} />
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "20px",
                  }}
                >
                  {loadingmore && (
                    <PropagateLoader color="#09dfeb" loading="true" size={15} />
                  )}
                </div>
                <div className="movie-grid__loadmore">
                  {!empty && items.length > 14 && (
                    <OutlineButton className="small" onClick={loadMore}>
                      Load more ⬇️
                    </OutlineButton>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              height: "500px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              <PropagateLoader color="#09dfeb" loading="true" size={15} />
            </div>
          </div>
        )}
      </>
    </>
  );
};

const MovieSearch = (props) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState(props.keyword ? props.keyword : "");

  const goToSearch = useCallback(() => {
    if (keyword.trim().length > 0) {
      history.push(`/${props.category}/search/${keyword}`);
    }
  }, [keyword, props.category, history]);

  useEffect(() => {
    const enterEvent = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        goToSearch();
      }
    };
    document.addEventListener("keyup", enterEvent);
    return () => {
      document.removeEventListener("keyup", enterEvent);
    };
  }, [keyword, goToSearch]);
  // useEffect(()=>{props.getList()},[searchvalue])

  return (
    <>
      {/* <span style={{display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',fontFamily:'cursive',color:'#fcba03'}}>Enter only First word of movieName (i.e) iron for iron man </span>
            <br></br> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="movie-search">
          <Input
            autoFocus="true"
            type="text"
            placeholder="🔍 movie / series name..."
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
              props.setSearchTerm(e.target.value);
            }}
          />
          <Button className="small" onClick={goToSearch}>
            Search
          </Button>
        </div>
      </div>
    </>
  );
};

export default MovieGrid;
