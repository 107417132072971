import "swiper/swiper.min.css";
import "./assets/boxicons-2.0.7/css/boxicons.min.css";
import "./App.scss";
import { useEffect, useState } from "react";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { BrowserRouter, Route } from "react-router-dom";
import { useDetectAdBlock } from "adblock-detect-react";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Routes from "./config/Routes";
import ScrollToTop from "./ScrollToTop";
const Parse = require("parse");
Parse.initialize("SHOWFLIXAPPID", "SHOWFLIXMASTERKEY");
//javascriptKey is required only if you have it on server.

Parse.serverURL = "https://parse.showflix.shop/parse";
function App() {
  disableReactDevTools();
  const adBlockDetected = useDetectAdBlock();
  const [isLoggedin, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (adBlockDetected) {
      window.alert("ad block detected");
    }
  }, []);
  useEffect(() => {
    console.clear();
  });

  return (
    <BrowserRouter>
      <Route
        render={(props) => (
          <>
              <ScrollToTop />
              <Header {...props} />
              <Routes />
              <Footer />
          </>
        )}
      />
    </BrowserRouter>
  );
}

export default App;
