import React, { useState, useEffect, Suspense } from "react";
import PropTypes from "prop-types";
import PropagateLoader from "react-spinners/PropagateLoader";
import "./movie-list.scss";
import { SwiperSlide, Swiper } from "swiper/react";
import { Link } from "react-router-dom";
import loadmore from "../../assets/iconmore.jpg";
const MovieCard = React.lazy(() => import("../movie-card/MovieCard"));
const Parse = require("parse");
const MovieList = (props) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    var classQuery;
    var Queryval;
    Queryval = "languages";
    if (props.category === "movie") {
      classQuery = "moviesv2";
    } else {
      classQuery = "seriesv2";
    }
    const getRecentList = async () => {
        try {
          const Movies = Parse.Object.extend(classQuery);
          const query = new Parse.Query(Movies);
          //query.containedIn(Queryval, ["Tamil"]);
          query.descending("updatedAt");
          query.limit(30);
          const results = await query.find();
          //const shuffled = filtered.sort(() => Math.random() - 0.5);
          setItems(results);
        } catch {
          console.log("error");
        }
      };
    const gettamList = async () => {
      try {
        const Movies = Parse.Object.extend(classQuery);
        const query = new Parse.Query(Movies);
        query.containedIn(Queryval, ["Tamil"]);
        query.descending("createdAt");
        query.limit(30);
        const results = await query.find();
        const shuffled = results.sort(() => Math.random() - 0.5);
        setItems(shuffled);
      } catch {
        console.log("error");
      }
    };
    const getengList = async () => {
      try {
        const Movies = Parse.Object.extend(classQuery);
        const query = new Parse.Query(Movies);
        query.containedIn(Queryval, ["English"]);
        query.limit(30);
        query.descending("createdAt");
        const results = await query.find();
        const shuffled = results.sort(() => Math.random() - 0.5);
        setItems(shuffled);
      } catch {
        console.log("error");
      }
    };
    const gettelList = async () => {
      try {
        const Movies = Parse.Object.extend(classQuery);
        const query = new Parse.Query(Movies);
        query.containedIn(Queryval, ["Telugu"]);
        query.limit(30);
        query.descending("createdAt");
        const results = await query.find();
        const shuffled = results.sort(() => Math.random() - 0.5);
        setItems(shuffled);
      } catch {
        console.log("error");
      }
    };
    const gethinList = async () => {
      try {
        const Movies = Parse.Object.extend(classQuery);
        const query = new Parse.Query(Movies);
        query.containedIn(Queryval, ["Hindi"]);
        query.limit(30);
        query.descending("createdAt");
        const results = await query.find();
        const shuffled = results.sort(() => Math.random() - 0.5);
        setItems(shuffled);
      } catch {
        console.log("error");
      }
    };
    const getmalList = async () => {
      try {
        const Movies = Parse.Object.extend(classQuery);
        const query = new Parse.Query(Movies);
        query.containedIn(Queryval, ["Malayalam"]);
        query.limit(30);
        query.descending("createdAt");
        const results = await query.find();
        const shuffled = results.sort(() => Math.random() - 0.5);
        setItems(shuffled);
      } catch {
        console.log("error");
      }
    };
    const gettamdubList = async () => {
      try {
        const Movies = Parse.Object.extend(classQuery);
        const query = new Parse.Query(Movies);
        query.containedIn(Queryval, ["Tamil Dubbed"]);
        query.limit(30);
        query.descending("createdAt");
        const results = await query.find();
        const shuffled = results.sort(() => Math.random() - 0.5);
        setItems(shuffled);
      } catch {
        console.log("error");
      }
    };
    switch (props.type) {
      case "Tamil":
        gettamList();
        break;
      case "Telugu":
        gettelList();
        break;
      case "Malayalam":
        getmalList();
        break;
      case "English":
        getengList();
        break;
      case "Tamil Dubbed":
        gettamdubList();
        break;
      case "Hindi":
        gethinList();
        break;
      default:
        getRecentList();
        break;
    }
  }, []);

  return (
    <>
      {items.length ? (
        <div className="movie-list">
          <Swiper grabCursor={true} spaceBetween={10} slidesPerView={"auto"}>
            {items.map((item, i) => (
              <SwiperSlide key={i}>
                <Suspense
                  fallback={
                    <div>
                      <PropagateLoader></PropagateLoader>
                    </div>
                  }
                >
                  <MovieCard item={item} category={props.category} />
                </Suspense>
              </SwiperSlide>
            ))}
            <SwiperSlide>
              <Link
                to={
                  props.type !== "latest" && props.type !== "toprated"
                    ? `lan/${props.category}/${props.type}`
                    : `${props.category}`
                }
              >
                <img style={{ paddingTop: "30%" }} src={loadmore}></img>
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <PropagateLoader color="#09dfeb" loading="true" size={15} />
          </div>
        </div>
      )}
    </>
  );
};

MovieList.propTypes = {
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MovieList;
