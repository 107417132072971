import React from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DownloadIcon from "@mui/icons-material/Download";
import Button, { OutlineButton } from "../../components/button/Button";
const EpisodesList = ({ episodes, playHandler, dHandler }) => {
  return (
    <>
      {episodes.length > 0 ? (
        <>
          <h3 style={{ color: "black", padding: "15px", textAlign: "center" }}>
            Play Online
            <PlayCircleIcon
              style={{
                fontSize: "medium",
                marginLeft: "2px",
                marginTop: "2px",
              }}
            ></PlayCircleIcon>
          </h3>
          {episodes.map((episode) => (
            <span key={episode.objectId}>
              <Button
                onClick={() => playHandler("series", episode.objectId)}
                className="butn"
              >
                Episode {episode.episodeNumber}{" "}
                <PlayCircleIcon
                  style={{
                    fontSize: "medium",
                    marginLeft: "2px",
                    marginTop: "2px",
                  }}
                ></PlayCircleIcon>
              </Button>
            </span>
          ))}
          <h3 style={{ color: "black", padding: "15px", textAlign: "center" }}>
            Download
            <DownloadIcon
              style={{
                fontSize: "medium",
                marginLeft: "2px",
                marginTop: "2px",
              }}
            ></DownloadIcon>
          </h3>
          {episodes.map((episode) => (
            <span key={episode.objectId}>
              <OutlineButton onClick={() => dHandler(episode)} className="botn">
                Episode {episode.episodeNumber}
                {"  "}
                <DownloadIcon
                  style={{
                    fontSize: "medium",
                    marginLeft: "2px",
                    marginTop: "2px",
                  }}
                ></DownloadIcon>
              </OutlineButton>
            </span>
          ))}
        </>
      ) : (
        <p
          style={{
            padding: "15px",
            textAlign: "center",
            fontStyle: "italic",
            color: "#999",
          }}
        >
          Will upload Episodes soon ...
        </p>
      )}
    </>
  );
};

export default EpisodesList;
