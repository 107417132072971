import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import PropagateLoader from "react-spinners/PropagateLoader";
import "./Play.scss";
import telelogo from "../assets/telegramlogo.gif";
import playimg from "../assets/play.gif";
import axios from "axios";
import Button, { OutlineButton } from "../components/button/Button";
import LangChange from "../assets/language change.png";
const Parse = require("parse");
const telegramlink = () => {
  window.open("https://telegram.me/showflix_movie", "_blank")?.focus();
};
const telegramHandler = (errortext, errorlink, src, title, result) => {
  const category = errorlink.split("-")[0].replace("play/", "");
  const objectid = errorlink.split("-")[1];
  const chatidsf = [-1001974007384];
  const photoidsf = result.attributes.posterURL;
  const markup = {
    inline_keyboard: [
      [
        {
          text: "🔰 Check Now 🔰",
          url: `https://showflix.site${errorlink}`,
          hide: false,
        },
      ],
    ],
  };
  const caption = `✵°✵.｡.✰ Error 𝕋𝕚𝕞𝕖 ✰.｡.✵°✵\n\n\n⚠️<b> Error Page :</b> <b><a href=\"https://showflix.site${errorlink}\"> ${errortext} </a></b> \n\n 🎥<b> Error ${category} :</b> <b><a href=\"https://showflix.site${category}/${objectid}\"> ${title} </a></b> \n\n 🎥<b> Error Source :</b> <b>${src}</b>\n\n 📅<b> Date & Time :</b> <b> ${new Date().toLocaleString()} </b> \n\n  ██▓▒­░⡷⠂𝚂𝙷𝙾𝚆𝙵𝙻𝙸𝚇⠐⢾░▒▓██`;
  let extra = {
    chat_id: chatidsf[0],
    photo: photoidsf,
    reply_markup: markup,
    caption: caption,
    parse_mode: "html",
  };
  const url =
    "https://api.telegram.org/bot5409240050:AAE7cHFsEnD7Ne_cmqzekeBvH7dK2uVr7Tg/sendPhoto";
  axios
    .post(url, extra)
    .then((response) => {
      alert(
        `🙏 Thanks for letting us know... \n 🎫 Your Ticket id : ${response.data.result.message_id}`
      );
    })
    .catch((error) => {
      console.error(error);
      let extra = {
        chat_id: chatidsf[0],
        photo: "https://1.showflixml.workers.dev/0:/logo1.png",
        reply_markup: markup,
        caption: caption,
        parse_mode: "html",
      };
      axios
        .post(url, extra)
        .then((response) => {
          alert(
            `🙏 Thanks for letting us know... \n 🎫 Your Ticket id : ${response.data.result.message_id} \n Contact @𝕤𝕙𝕠𝕨𝕗𝕝𝕚𝕩𝕤𝕦𝕡𝕡𝕠𝕣𝕥_𝕓𝕠𝕥 in telegram with ticket id to know the status`
          );
        })
        .catch((error) => {
          console.error(error.message);
          if (error.message === "Network Error") {
            alert(`🙏 ${error.message} Please use VPN and try again \n`);
          }
        });
    });
};
const BannerInt = () => {
  const bannerint = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannerint.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://wugroansaghadry.com/401/8572691`;
      script["data-cfasync"] = false;
      script.async = true;
      if (bannerint.current) {
        bannerint.current.append(script);
      }
    }
  });
  return (
    <div>
      <div ref={bannerint}></div>
      <div id=""></div>
    </div>
  );
};
const BannerPopUnder = () => {
  const bannerpopunder = useRef(HTMLDivElement);
  useEffect(() => {
    if (!bannerpopunder.current.firstChild) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://arvigorothan.com/tag.min.js`;
      script.async = true;
      script.setAttribute("data-zone", 7635799);

      if (bannerpopunder.current) {
        bannerpopunder.current.append(script);
      }
    }
  });
  return (
    <>
      <div ref={bannerpopunder}></div>
      <div id="7635799"></div>
    </>
  );
};
const ReportFormTele = ({ setReportFlag, src, title, result }) => {
  const [errorDetail, setErrorDetail] = useState("");
  function handle_click(event) {
    telegramHandler(errorDetail, window.location.pathname, src, title, result);
    setErrorDetail("");
    setReportFlag(false);
    event.preventDefault();
  }
  return (
    <>
      <form onSubmit={handle_click}>
        <div>
          <input
            style={{
              minHeight: "70px",
              margin: "auto",
              minWidth: "70%",
              backgroundColor: "rgb(225, 236, 236)",
              color: "#000",
            }}
            autoFocus="true"
            type="text"
            placeholder="Enter Error Details to unlock Submit button"
            value={errorDetail}
            onChange={(e) => {
              setErrorDetail(e.target.value);
            }}
          />
        </div>
        <div>
          <Button type="Submit" disabled={!errorDetail}>
            {" "}
            Submit{" "}
          </Button>
        </div>
      </form>
    </>
  );
};

const PointstoRemember = () => {
  return (
    <>
      <h3 style={{ color: "Red" }}>Points To Remember Before Reporting</h3>
      <br></br>
      <ul style={{ color: "green", fontWeight: "bold" }}>
        <li>
          1 : If Video Buffers Plese check your internet connection or select
          low quality.{" "}
        </li>
        <br></br>
        <li>
          2 : If Video is not Playing use VPN{" "}
          <a href="https://1.1.1.1/" style={{ color: "blue" }}>
            (1.1.1.1 - Download here)
          </a>{" "}
          and try again.{" "}
        </li>
        <br></br>
        <li>
          3 : To Change Audio click the setting icon and choose the language{" "}
        </li>
        <img src={LangChange} style={{ maxWidth: "300px" }}></img>
        <br></br>
        <li>
          4 : Please Enter Movie name or Series Name & Season & Episode number
          and what is the issue in detail
        </li>
      </ul>
    </>
  );
};
const Play = () => {
  const propspar = useParams();
  const id = propspar.id;
  const category = id.split("-")[0];
  const objectid = id.split("-")[1];
  const [title, settitle] = useState();
  const [load, setload] = useState(true);
  const [loading, setLoading] = useState(true);
  const [src, setsrc] = useState("");
  const [reportFlag, setReportFlag] = useState(false);
  const [embedLinks, setEmbedLinks] = useState(null);
  const [item, setitem] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  useEffect(() => {
    const getDetail = async () => {
      if (category === "movie") {
        try {
          const query = new Parse.Query("moviesv2");
          const results = await query.get(objectid);
          const languages = results.attributes.languages.join(" + ");
          const fulltitle = `${results.attributes.name} (${results.attributes.releaseYear}) [ ${languages} ]`;
          settitle(fulltitle);
          setitem(results);
          setEmbedLinks(results.attributes.embedLinks);
          setLoading(false);
        } catch (e) {
          console.log("Error fetching player id", e);
          setLoading(true);
        }
      } else {
        try {
          const query = new Parse.Query("episodev2");
          const results = await query.get(objectid);
          const seriesquery = new Parse.Query("seriesv2");
          seriesquery.matches(
            "name",
            `^${results.attributes.seriesName}$`,
            "i"
          );
          const seriesResults = await seriesquery.first();
          const languages = seriesResults.attributes.languages.join(" + ");
          const fulltitle = `${results.attributes.seriesName} ${results.attributes.seasonNumber} ${results.attributes.name} [ ${languages} ]`;
          settitle(fulltitle);
          setitem(results);
          setEmbedLinks(results.attributes.embedLinks);
          setLoading(false);
        } catch (e) {
          console.log("Error fetching player id", e);
          setLoading(true);
        }
      }
    };
    if (propspar.id) {
      getDetail();
    }
  }, [category, id]);
  const setLoad = () => {
    settitle(title + ` ${platformDescriptions[selectedPlatform]} - SF`);
    setload(false);
  };
  // Mapping base URLs for each platform
  const platformBaseUrls = {
    upnshare: "https://showflix.upns.one/#",
    streamruby: "https://showflix.beauty/embed-",
    streamwish: "https://embedwish.com/e/",
    vihide: "https://vidhidepro.com/v/",
  };
  const platformDescriptions = {
    upnshare: "Multi Quality (Best Player ✨)",
    streamruby: "Multi Quality (SuperFast Player 🚀)",
    vihide: "720p/360p Quality",
    streamwish: "1080p Quality",
  };
  // Handler for selecting a platform
  const handlePlatformSelect = (platform) => {
    setSelectedPlatform(platform);
  };
  return (
    <>
      {!loading ? (
        <>
          <BannerInt></BannerInt>
          <BannerPopUnder></BannerPopUnder>
          <div style={{ paddingTop: "120px" }}>
            <div
              className="playcard"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {!selectedPlatform ? (
                <>
                  <div
                    style={{
                      textAlign: "center",
                      margin: "25px",
                      color: "black",
                    }}
                  >
                    <h1 style={{ wordBreak: "break-all" }}>Select Player : <b style={{color:"#ed1c73"}}>{title}</b></h1>
                    <h4 style={{ color: "green" }}>
                      If video Buffers Choose different player
                    </h4>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginLeft: "25px",
                      marginRight: "25px",
                      marginBottom: "25px",
                      color: "black",
                    }}
                  >
                    {Object.keys(embedLinks).map(
                      (platform) =>
                        embedLinks[platform] !== null && (
                          <Button
                            key={platform}
                            onClick={() => handlePlatformSelect(platform)}
                          >
                            <img
                              style={{
                                maxWidth: 75,
                                maxHeight: 25,
                                borderRadius: 5,
                              }}
                              src={playimg}
                              alt="Play"
                            />
                            <span style={{ color: "black" }}>
                              {" "}
                              {platformDescriptions[platform]}
                            </span>
                          </Button>
                        )
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      textAlign: "center",
                      margin: "25px",
                      color: "black",
                    }}
                  >
                    {load ? (
                      <h1>Loading...</h1>
                    ) : (
                      <h1 style={{ wordBreak: "break-all" }}>{title}</h1>
                    )}
                  </div>
                  <div className="containerdiv">
                    <iframe
                      id="showflixIframe"
                      onLoad={setLoad}
                      className="responsive-iframe"
                      src={
                        selectedPlatform !== "upnshare"
                          ? `${platformBaseUrls[selectedPlatform]}${embedLinks[selectedPlatform]}.html`
                          : `${platformBaseUrls[selectedPlatform]}${embedLinks[selectedPlatform]}`
                      }
                      allowFullScreen
                    ></iframe>
                  </div>
                  <span style={{ paddingLeft: 10, textAlign: "center" }}>
                    <OutlineButton className="butn" onClick={telegramlink}>
                      {" "}
                      <>
                        {" "}
                        <img
                          style={{
                            maxWidth: 25,
                            maxHeight: 25,
                            borderRadius: 5,
                          }}
                          src={telelogo}
                          alt="Telegram link"
                        />{" "}
                        <span style={{ color: "black" }}>Join us</span>{" "}
                      </>
                    </OutlineButton>
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "5px",
                    }}
                  >
                    {/* <h4 style={{color:'red',padding:'5px'}}>Sorry for the recent issues on video player.we are solving Please wait for few days...</h4> */}
                    <button
                      style={{
                        color: "Red",
                        fontWeight: "bolder",
                        borderRadius: "5px",
                        background: "yellow",
                        padding: "5px",
                        textAlign: "center",
                      }}
                      onClick={() => setReportFlag(!reportFlag)}
                    >
                      ⚠️ Report Error
                    </button>
                  </div>
                  {reportFlag && (
                    <div
                      className="playcard"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <PointstoRemember></PointstoRemember>
                      <br></br>
                      <ReportFormTele
                        setReportFlag={setReportFlag}
                        src={selectedPlatform}
                        title={title}
                        result={item}
                      ></ReportFormTele>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "700px",
            width: "100%",
          }}
        >
          <div>
            <PropagateLoader color="#09dfeb" loading="true" size={15} />
          </div>
        </div>
      )}
    </>
  );
};

export default Play;
