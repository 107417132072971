import React, { useEffect,useState,useRef} from 'react';
import './footer.scss';
import { Link } from 'react-router-dom';
import bg from '../../assets/footer-bg.jpg';
import logo from '../../assets/logo1.png';
const Parse = require('parse')
Parse.initialize('SHOWFLIXAPPID','SHOWFLIXMASTERKEY')
Parse.serverURL = 'https://parse.showflix.shop/parse'
const Footer = () => {
    const [total,setTotal]=useState();
    const [totals,setTotals]=useState();
    useEffect(async () => {
        const Movies = Parse.Object.extend("moviesv2");
        const query = new Parse.Query(Movies);
      query.count().then(function(result){setTotal(result)});
      const Moviess = Parse.Object.extend("seriesv2");
      const querys = new Parse.Query(Moviess);
    querys.count().then(function(result){setTotals(result)});
     
    }, [])
    
    return (
        <div className="footer" style={{backgroundImage: `url(${bg})`}}>
            <div className="footer__content container">
            <h3 style={{textAlign:'center',color:'#E1FFB1'}}>{`Total ${total}  Movies & ${totals} Series`}</h3>
            <br></br>
            <h5 style={{textAlign:'center',color:'#FFABE1'}}> 𝗔𝗱𝗱𝗶𝗻𝗴 𝗺𝗮𝗻𝘆 𝗺𝗼𝗿𝗲 🚀 - 𝗣𝗹𝗲𝗮𝘀𝗲 𝘀𝗵𝗮𝗿𝗲 𝗮𝗻𝗱 𝘀𝘂𝗽𝗽𝗼𝗿𝘁 𝗨𝘀 ❤️</h5>
            <br></br>
            <br></br>
                <div className="footer__content__logo">
                    <div className="logo">
                        <Link to="/"> <img src={logo} alt="" /></Link>
                    </div>
                </div>
                <div className="footer__content__menus">
                    <div className="footer__content__menu">
                        <Link to="/"> 🏠 Home</Link>
                        {/* <a href = "mailto: admin@showflix.in"> 📧 Send Email</a> */}
                        <Link to="/terms"> 📜 Term of services</Link>
                        <a href = "https://showflix.shop/?page_id=137">For DMCA or Copywright Claims</a>
                       
                    </div>
                    <div className="footer__content__menu">
                        <a href="https://telegram.me/showflix_group">💬 Telegram Group</a>
                    </div>
                </div>
                <br></br>
                     <div >
                    <span style={{fontSize:'12px',textAlign:'center'}}>
                    Showflix does not host any files on its servers. All files and contents are hosted on non-affiliate third party websites.We are not responsible for that content.
                    </span>
                    </div>
            </div>

        </div>
    );
}

export default Footer;
