import React from "react";

import "./movie-card.scss";
import placeholder1 from "../../assets/sfgif.gif";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import PreloadImage from "../../PreloadImage";

const MovieCard = (props) => {
  const item = props.item;
  const link = "/" + props.category + "/" + item.id;
  const name = item.attributes.name;
  const year = item.attributes.releaseYear;
  const namewithyear = `${name} (${year})`;
  const languages = item.attributes.languages;
  const bg = item.attributes.posterURL.replace("www.themoviedb.org","image.tmdb.org") || item.attributes.backdrop.replace("www.themoviedb.org","image.tmdb.org");
  // style={{backgroundImage: `url(${bg})`}}
  return (
    <Link to={link}>
      <div className="movie-card">
        <PreloadImage lazy src={bg} placeholder={placeholder1}></PreloadImage>
        <Button>
          <i className="bx bx-play"></i>
        </Button>
      </div>
      <h3>
        {namewithyear}
        <div className="lan">
          {languages.map((language, index) => (
            <span key={index}>{language} </span>
          ))}
        </div>
      </h3>
    </Link>
  );
};

export default MovieCard;
